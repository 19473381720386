import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import SignUp from 'views/auth/views/signup'
import SignIn from 'views/auth/views/signin'
import SendForgotPasswordLink from 'views/auth/views/forgotpassword/SendForgotPasswordLink'
import SendEmailVerificationCode from 'views/auth/views/verifyemail/SendEmailVerificationCode'
import Home from 'views/home'
import Profile from 'views/profile'
import Project from 'views/project'
import Settings from 'views/settings'
import Process from 'views/process'
import Table from 'views/table'
import AppSidebar from 'components/sidebar'
import NoPermission from 'views/auth/views/nopermission'
import Integrations from 'views/integrations'
import Subscription from 'views/subscription'
import Security from 'views/security'
import { PROJECT as CONSTANT_PROJECT } from 'app-constants'

export const BASE = '/'
export const HOME = '/home'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const NO_PERMISSION = '/nopermission'
export const VERIFY_EMAIL = '/verify-email'
export const CONFIRM_EMAIL = '/confirm-email'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const APPLICATION_HOME = '/home'
export const PROFILE = '/profile/:userId/:tab'
export const INTEGRATIONS = '/settings/integrations'
export const SUBSCRIPTION = '/settings/subscription'
export const SECURITY = '/settings/security'
export const PROJECT = '/project/:projectId'
export const TABLE_BASE = '/table/'
export const TABLE_VIEW_BASE = '/view/'
export const TABLE = '/project/:projectId/table/:tableId/view/:viewId'
export const PROCESS = '/project/:projectId/process/:processId'
export const PROCESS_BASE = '/process/'
export const PROJECT_BASE = '/project/'
export const SETTINGS = '/project/:projectId/settings'
export const TERMS = 'https://morta.io/terms'
export const PRIVACY = 'https://morta.io/privacy'
export const TWO_FACTOR_SIGNIN = '/signin/2fa'
export const ADMIN_HOME = '/admin/home'
export const ADMIN_CELERY = '/admin/celery'
export const ADMIN_REDIS = '/admin/redis'
export const ADMIN_LOGS = '/admin/logs'

import AuthGuard from 'components/guard/AuthGuard'
import ConfirmEmailVerificationCode from 'views/auth/views/verifyemail/ConfirmEmailVerificationCode'
import SetNewPassword from 'views/auth/views/forgotpassword/SetNewPassword'
import GoogleComplete from 'views/auth/views/oauth/GoogleComplete'
import MicrosoftComplete from 'views/auth/views/oauth/MicrosoftComplete'
import MasqueradeWarning from 'views/auth/components/MasqueradeWarning'
import FrontChannelLogout from 'views/auth/views/oauth/Logout'
import TwoFactorLogin from 'views/auth/views/2fa'
import AdminHome from 'views/admin/components/home'
import AdminCelery from 'views/admin/components/celery'
import AdminRedis from 'views/admin/components/redis'
import { useProject } from 'hooks/project'
import ArchivedWarning from 'components/archived'
import FreeTrial from 'components/trial'
import Audit from 'components/audit'

// Oauth
export const GOOGLE_OAUTH_COMPLETE = '/oauth/google/complete'
export const MICROSOFT_OAUTH_COMPLETE = '/oauth/microsoft/complete'
export const FRONT_CHANNEL_LOGOUT = '/oauth/logout'

// User must be logged in for any of these routes, otherwise redirected by AuthGuard
// to the /signin page
const AuthenticatedRoutes: React.FC = () => {
  const { project } = useProject()
  return (
    <AuthGuard>
      <div style={{ display: 'inline-flex', width: 'inherit' }}>
        <AppSidebar />
        <MasqueradeWarning />

        {project.isDeleted && (
          <ArchivedWarning message={`This ${CONSTANT_PROJECT} has been archived by the ${CONSTANT_PROJECT} owner`} />
        )}

        <div className="flex flex-row flex-wrap w-full h-full background-white overflow-auto">
          <Switch>
            <Route exact={true} path={APPLICATION_HOME} component={Home} />
            <Route exact={true} path={PROJECT} component={Project} />
            <Route exact={true} path={HOME} component={Home} />
            <Route exact={false} path={PROCESS} component={Process} />
            <Route exact={false} path={PROFILE} component={Profile} />
            <Route exact={false} path={SETTINGS} component={Settings} />
            <Route exact={false} path={TABLE} component={Table} />
            <Route exact={false} path={SECURITY} component={Security} />
            <Route exact={false} path={INTEGRATIONS} component={Integrations} />
            <Route exact={false} path={SUBSCRIPTION} component={Subscription} />

            {/* Not Found Paths */}
            <Route exact={true} path={NO_PERMISSION} component={NoPermission} />

            {/* Admin routes (still accessible by non super admins, but unusable - security enforced by backend) */}
            <Route exact={true} path={ADMIN_HOME} component={AdminHome} />
            <Route exact={true} path={ADMIN_CELERY} component={AdminCelery} />
            <Route exact={true} path={ADMIN_REDIS} component={AdminRedis} />
            <Route exact={true} path={ADMIN_LOGS} component={Audit} />

            {/* Front channel logout */}
            <Route exact={true} path={FRONT_CHANNEL_LOGOUT} component={FrontChannelLogout} />

            {/* For logged in users, redirect to home */}
            <Route>
              <Redirect to={HOME} />
            </Route>
          </Switch>

          <FreeTrial />
        </div>
      </div>
    </AuthGuard>
  )
}

const Routes: React.FC = () => {
  return (
    <div className="flex bg-white text-primary h-screen w-full">
      <Switch>
        <Route exact={true} path={SIGN_UP} component={SignUp} />
        <Route exact={true} path={SIGN_IN} component={SignIn} />
        <Route exact={true} path={TWO_FACTOR_SIGNIN} component={TwoFactorLogin} />
        <Route exact={true} path={FORGOT_PASSWORD} component={SendForgotPasswordLink} />
        <Route exact={true} path={RESET_PASSWORD} component={SetNewPassword} />
        <Route exact={true} path={VERIFY_EMAIL} component={SendEmailVerificationCode} />
        <Route exact={true} path={CONFIRM_EMAIL} component={ConfirmEmailVerificationCode} />

        {/* Oauth */}
        <Route exact={true} path={GOOGLE_OAUTH_COMPLETE} component={GoogleComplete} />
        <Route exact={true} path={MICROSOFT_OAUTH_COMPLETE} component={MicrosoftComplete} />

        <AuthenticatedRoutes />

        {/* Not Found Paths */}
        <Route exact={true} path={NO_PERMISSION} component={NoPermission} />

        {/* For non logged in users, redirect to signin page */}
        <Route>
          <Redirect to={SIGN_IN} />
        </Route>
      </Switch>
    </div>
  )
}

export default withRouter(Routes)
