import React from 'react'

import { IUserObject } from 'types'
import { setMasqueradeToken, clearMasqueradeToken, getMasqueradeToken } from 'helpers/auth'
import api, { NewUserParams } from 'helpers/api'
import * as Sentry from '@sentry/browser'

const AUTH_KEY = 'morta_user'

const _useAuthProvider = () => {
  const [user, setUser] = React.useState<IUserObject>()

  const login = async (emailAddress: string, password: string) => {
    const response = await api.loginUser(emailAddress, password)
    updateUser(response.data)
    clearMasqueradeToken()
  }

  const register = async (userData: NewUserParams) => {
    await api.createUser(userData)
    clearMasqueradeToken()
  }

  const logout = async (serverSideLogout = true) => {
    if (!getMasqueradeToken() && serverSideLogout) {
      try {
        await api.logoutUser()
      } catch (e) {
        console.error(e)
      }
    }
    // Ordering of these calls is important, localstorage must be cleared first
    // before clearing user state
    localStorage.removeItem(AUTH_KEY)
    Sentry.setUser(null)
    setUser(undefined)
    clearMasqueradeToken()
  }

  const updateUser = (newUserInfo: IUserObject) => {
    const authToken = newUserInfo.authToken ? newUserInfo.authToken : user?.authToken
    const favourites = newUserInfo.favourites ? newUserInfo.favourites : user?.favourites ? user.favourites : []
    const isSuperAdmin = newUserInfo.isSuperAdmin ? newUserInfo.isSuperAdmin : user?.isSuperAdmin
    localStorage.setItem(AUTH_KEY, JSON.stringify(newUserInfo))
    Sentry.setUser({ email: newUserInfo.email })
    setUser({ ...newUserInfo, authToken, favourites, isSuperAdmin })
  }

  const startGoogleAuth = async () => {
    try {
      const response = await api.getGoogleOauthConnectUrl()
      window.location.href = response.data.url
    } catch (e) {}
  }

  const startMicrosoftAuth = async () => {
    const response = await api.getMicrosoftOauthConnectUrl()
    window.location.href = response.data.url
  }

  const startMasquerade = async (token: string) => {
    setMasqueradeToken(token)
    const response = await api.getMe()
    updateUser(response.data)
  }

  return {
    user,
    logout,
    login,
    register,
    updateUser,
    startGoogleAuth,
    startMicrosoftAuth,
    startMasquerade
  }
}

type AuthContextType = ReturnType<typeof _useAuthProvider>

const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = _useAuthProvider()
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth can only be used within an Auth context')
  }

  return context
}

export default useAuth
