import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { IUserObject, IApiKey } from 'types'
import { Copy } from 'components/icons'

interface NewKeyModalProps extends ModalProps {
  setUserData: (user: IUserObject) => void
}

const NewKeyModal: React.FC<NewKeyModalProps> = ({ id, open, setOpen, onClose, setUserData }: NewKeyModalProps) => {
  const [apiKey, setAPIKey] = useState<Partial<IApiKey>>({
    name: 'New API Key'
  })

  const [stage, setStage] = useState({
    key: '',
    number: 0
  })

  async function handleClick(): Promise<null | undefined> {
    if (!apiKey || apiKey.name === '') return null

    const response = await api.createApiKey(apiKey)

    setStage({ key: response.data.tempKey, number: 1 })

    const updateUserResponse = await api.getMe()
    setUserData(updateUserResponse.data)
  }

  function handleSave(): void {
    setAPIKey({
      name: 'New API Key'
    })

    setStage({
      key: '',
      number: 0
    })

    setOpen(false)
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} onClose={onClose} title="New API Key">
      {stage.number === 0 && (
        <div>
          <div className="mb-8">
            <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">New API Key Name</div>
            <input
              className="w-full"
              data-cy="api-key-name"
              required
              style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setAPIKey({ ...apiKey, name: event.target.value })
              }
            />
          </div>

          <div className="flex justify-end mt-20px">
            <Button data-cy="generate-new-api-key" style={{ marginTop: '40px' }} onClick={() => handleClick()}>
              Generate New API Key
            </Button>
          </div>
        </div>
      )}

      {stage.number === 1 && (
        <div>
          Here is your API key - please make sure you save this in a safe place as we do not save your API key (for
          security reasons).
          <div className="flex flex-row items-center rounded heavy-shadow w-full p-10px flex-wrap my-30px border-1px border-solid border-black select-none">
            <div data-cy="generated-api-key">{stage.key}</div>
            <span
              onClick={(): Promise<void> => navigator.clipboard.writeText(stage.key)}
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
            >
              <Copy />
            </span>
          </div>
          <div className="flex justify-end mt-20px">
            <Button data-cy="saved-key" onClick={(): void => handleSave()}>
              I Have Saved My Key
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default NewKeyModal
