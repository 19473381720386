import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import api from 'helpers/api'
import { IContext, IProject, ITableViewColumn, ITableViewWithColumns } from 'types'
import Select from 'components/select'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'
import { PROJECT } from 'app-constants'
import { ISpreadsheetData, SpreadsheetReducerActions } from 'components/spreadsheet/types'
import { capitaliseFirstLetter } from 'helpers/utils'
import { history } from 'helpers/history'
import Flag from 'components/flag'
import constants from 'style/constants.module.scss'
import { convertToApiColour, convertToApiFilter } from 'components/spreadsheet/helpers/functions'
import { SELECT_COLUMN_TYPES } from 'components/spreadsheet/constants/const'

interface DuplicateProps extends ModalProps {
  type: 'table' | 'hub' | 'process' | 'view'
  publicId: string
  parentPublicId?: string
  spreadsheetData?: ISpreadsheetData
  setSpreadsheetData?: React.Dispatch<SpreadsheetReducerActions>
}

const Duplicate: React.FC<DuplicateProps> = ({
  id,
  open,
  setOpen,
  type,
  publicId,
  parentPublicId,
  spreadsheetData,
  setSpreadsheetData
}) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const [projects, setProjects] = useState<IProject[]>()
  const [targetProjectId, setTargetProjectId] = useState<string>()
  const [duplicateTables, setDuplicateTables] = useState<boolean>(id === 'use-template' ? true : false)
  const [duplicatePermissions, setDuplicatePermissions] = useState<boolean>(true)
  const [lockResource, setLockResource] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  useEffect(() => {
    if (project && project.publicId !== '') {
      setTargetProjectId(project.publicId)
    }
  }, [project])

  useEffect(() => {
    api
      .getOwnerProjects()
      .then((response) => {
        if (response && response.data) {
          setProjects(
            response.data
              .filter((project: IProject) => !project.isDeleted)
              .sort((a: IProject, b: IProject) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          )
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }, [])

  const duplicate = () => {
    setSaving(true)
    if (type === PROJECT) {
      api
        .duplicateProject({ publicId, duplicatePermissions: true, lockResource })
        .then(() => {
          setSnackbarMessage({
            status: 'success',
            message: 'Duplication has started. You will receive an email once it has been complete.'
          })
          setSaving(false)
          setOpen(false)
        })
        .catch((error) => {
          setSaving(false)
          displayErrorMessage(error)
        })
      return
    }

    if (targetProjectId) {
      if (type === 'table') {
        api
          .duplicateTable({
            publicId,
            targetProjectId,
            duplicatePermissions,
            duplicateTables
          })
          .then(() => {
            setSnackbarMessage({
              status: 'success',
              message: 'Duplication has started. You will receive an email once it has been complete.'
            })
            setSaving(false)
            setOpen(false)
          })
          .catch((error) => {
            setSaving(false)
            displayErrorMessage(error)
          })
      } else if (type === 'process') {
        api
          .duplicateProcess({
            publicId,
            targetProjectId,
            duplicatePermissions,
            duplicateTables,
            lockResource
          })
          .then(() => {
            setSnackbarMessage({
              status: 'success',
              message: 'Duplication has started. You will receive an email once it has been complete.'
            })
            setSaving(false)
            setOpen(false)
          })
          .catch((error) => {
            setSaving(false)
            displayErrorMessage(error)
          })
      } else if (type === 'view' && spreadsheetData && setSpreadsheetData) {
        if (parentPublicId === undefined || setSpreadsheetData === undefined) {
          setSnackbarMessage({ status: 'error' })
        }
        api
          .duplicateView(parentPublicId!, {
            publicId,
            duplicatePermissions
          })
          .then((response) => {
            const createdView = response.data as ITableViewWithColumns
            setSpreadsheetData!({ type: 'ADD_VIEW', view: createdView })

            const newTableView: Partial<ITableViewWithColumns> & IContext = {
              name: response.data.name,
              type: spreadsheetData.viewDetails.type,
              description: spreadsheetData.viewDetails.description,
              filterSettings: convertToApiFilter(spreadsheetData.userConfiguration.filterSettings),
              groupSettings: spreadsheetData.userConfiguration.groupSettings,
              sortSettings: spreadsheetData.userConfiguration.sortSettings,
              colourSettings: convertToApiColour(spreadsheetData.userConfiguration.colourSettings),
              chartSettings: spreadsheetData.userConfiguration.chartSettings,
              frozenIndex: spreadsheetData.viewDetails.frozenIndex,
              displayValidationErrorRows: spreadsheetData.viewDetails.displayValidationErrorRows,
              displayCommentRows: spreadsheetData.viewDetails.displayCommentRows,
              collapsedGroupView: spreadsheetData.viewDetails.collapsedGroupView,
              unpackMultiselectGroupView: spreadsheetData.viewDetails.unpackMultiselectGroupView,
              rowHeight: spreadsheetData.viewDetails.rowHeight,
              columns: spreadsheetData.viewDetails.columns
                .map((column) => {
                  const newColumn = JSON.parse(JSON.stringify(column))
                  if (newColumn['required'] === undefined) newColumn['required'] = false
                  if (SELECT_COLUMN_TYPES.includes(newColumn.kind) && newColumn.kindOptions?.tableOptions)
                    delete newColumn.kindOptions?.tableOptions['cachedOptions']

                  return newColumn
                })
                .filter(
                  (column: ITableViewColumn) =>
                    !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)
                ),
              context: { projectId: project.publicId }
            }

            return api.updateWholeTableView(createdView.publicId, newTableView)
          })
          .then((response) => {
            setSnackbarMessage({
              status: 'success',
              message: 'The view has been successfully duplicated.'
            })
            setSaving(false)
            setOpen(false)
            history.push(`/project/${project.publicId}/table/${parentPublicId}/view/${response.data.publicId}`)
            window.location.reload()
          })
          .catch((error) => {
            setSaving(false)
            displayErrorMessage(error)
          })
      }
    }
  }

  const typeName = type === 'table' ? 'table' : type === 'process' ? 'document' : type

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={id === 'use-template' ? 'Use Template' : `Duplicate ${capitaliseFirstLetter(typeName)}`}
    >
      {(type === 'table' || type === 'process') && (
        <div>
          <div style={{ marginBottom: '30px' }}>
            {id === 'use-template'
              ? `Please select the ${PROJECT} you want to use this template in. Only projects in which you are
            'administrator' or 'owner' will be shown.`
              : `Please select the ${PROJECT} you want to duplicate this ${typeName} to. Only projects in which you are
            'administrator' or 'owner' will be shown.`}
          </div>
          <Select
            options={
              projects
                ? projects.map((project) => {
                    return { value: project.publicId, label: project.name }
                  })
                : []
            }
            optionsSelected={targetProjectId ? [targetProjectId] : []}
            onOptionClick={(option: string) => setTargetProjectId(option)}
            loading={projects === undefined}
          />
        </div>
      )}
      <div>
        <div className="border-b-2px border-solid border-grey py-10px mb-4 mt-8 font-bold">Advanced Options</div>
        {type === 'hub' && (
          <div>
            <div style={{ marginBottom: '20px' }}>
              All tables, documents, {PROJECT} settings and webhooks will be duplicated into a new {PROJECT}.
            </div>
          </div>
        )}

        {type !== 'hub' && type !== 'view' && (
          <div className="flex items-center justify-center" style={{ marginTop: '20px' }}>
            <div>Do you want to duplicate all linked tables?</div>
            <select
              className="ml-auto"
              style={{ width: '150px' }}
              value={duplicateTables ? 'Yes' : 'No'}
              onChange={(event) => {
                if (event.target.value === 'Yes') {
                  setDuplicateTables(true)
                } else {
                  setDuplicateTables(false)
                }
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        )}
        {type !== 'hub' && (
          <div className="flex items-center justify-center" style={{ marginTop: '20px' }}>
            <div>Should the duplicate resource have the same permissions?</div>
            <select
              className="ml-auto"
              style={{ width: '150px' }}
              value={duplicatePermissions ? 'Yes' : 'No'}
              onChange={(event) => {
                if (event.target.value === 'Yes') {
                  setDuplicatePermissions(true)
                } else {
                  setDuplicatePermissions(false)
                }
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        )}
      </div>

      {(type === 'hub' || type === 'process') && (
        <div className="flex items-center justify-center" style={{ marginTop: '20px' }}>
          <div>Do you want to lock the duplicated resource(s)?</div>
          <Flag text="Enterprise" color={constants.blue} />
          <select
            className="ml-auto"
            style={{ width: '150px' }}
            value={lockResource ? 'Yes' : 'No'}
            onChange={(event) => {
              if (event.target.value === 'Yes') {
                setLockResource(true)
              } else {
                setLockResource(false)
              }
            }}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      )}

      <div className="flex justify-end" style={{ marginTop: '30px' }}>
        <Button internalType="danger" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          internalType="accept"
          onClick={() => duplicate()}
          style={{ marginLeft: '10px' }}
          disabled={(targetProjectId === undefined && ['process', 'table'].includes(type)) || saving}
          isLoading={saving}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default Duplicate
