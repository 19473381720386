import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'components/button'
import NewKeyModal from 'views/integrations/components/newKeyModal'
import EditKeyModal from 'views/integrations/components/editKeyModal'
import api from 'helpers/api'
import Modal from 'components/modal'
import useAuth from 'hooks/auth'
import useApplicationStore from 'hooks/application'
import { history } from 'helpers/history'
import { INTEGRATIONS } from 'routes'
import { IIntegrationRedirects } from 'types'
import { IntegrationProviders } from 'app-constants'
import IntegrationConnection, { BUTTON_WIDTH } from 'views/integrations/components/IntegrationConnection'

const Integrations: React.FC = () => {
  document.title = 'Integrations'
  const [newKeyModalOpen, setNewKeyModalOpen] = useState<boolean>(false)
  const [editKeyModalOpen, setEditKeyModalOpen] = useState<boolean>(false)
  const [deleteKeyDialogOpen, setDeleteKeyDialogOpen] = useState<boolean>(false)
  const [selectedAPIKey, setSelectedAPIKey] = useState<number>(-1)
  const [integrationRedirects, setIntegrationRedirects] = useState<IIntegrationRedirects>({
    autodesk: null,
    viewpoint: null,
    aconex: null,
    procore: null,
    revizto: null,
    asite: null
  })
  const [connectingAccount, setConnectingAccount] = useState<boolean>(false)
  const [reviztoAccessToken, setReviztoAccessToken] = useState<string>()
  const [reviztoRegion, selectReviztoRegion] = useState<string>('')

  const { user, updateUser } = useAuth()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const location = useLocation()
  const REVIZTO_REGIONS: Record<string, string> = {
    canada: 'Canada',
    dubai: 'United Arab Emirated',
    ireland: 'Ireland',
    ksa: 'Kingdom of Saudi Arabia',
    london: 'Europe (London)',
    saopaulo: 'South America (Brazil)',
    singapore: 'Southeast Asia (Singapore)',
    sydney: 'Australia',
    tokyo: 'Japan',
    virgina: 'North America (USA)'
  }

  const isConnected = (provider: IntegrationProviders) => {
    if (user) {
      switch (provider) {
        case IntegrationProviders.AUTODESK_BIM360:
          return user.autodeskConnected
        case IntegrationProviders.VIEWPOINT:
          return user.viewpointConnected
        case IntegrationProviders.ACONEX:
          return user.aconexConnected
        case IntegrationProviders.PROCORE:
          return user.procoreConnected
        case IntegrationProviders.REVIZTO:
          return user.reviztoConnected
        case IntegrationProviders.ASITE:
          return user.asiteConnected
        default:
          return false
      }
    }
    return false
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    // BIM 360 Auth Flow
    const integration = query.get('integration')
    const state = query.get('state')
    if (integration === 'autodesk') {
      const code = query.get('code')
      if (code) {
        connectAccount(IntegrationProviders.AUTODESK_BIM360, code)
      }
    }

    // Procore Auth Flow
    if (integration === 'procore') {
      const code = query.get('code')
      if (code) {
        connectAccount(IntegrationProviders.PROCORE, code)
      }
    }

    // Viewpoiont Auth Flow
    const iss = query.get('iss')
    if (iss === 'https://identity-uk.team.viewpoint.com') {
      const code = query.get('code')
      if (code) {
        connectAccount(IntegrationProviders.VIEWPOINT, code)
      }
    }

    // Aconex Auth Flow
    if (integration === 'aconex') {
      const code = query.get('code')
      if (code) {
        connectAccount(IntegrationProviders.ACONEX, code)
      }
    }

    // Asite Auth Flow
    if (integration === 'asite') {
      const code = query.get('code')
      if (code) {
        connectAccount(IntegrationProviders.ASITE, code)
      }
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    api
      .getIntegrationRedirects()
      .then((response) => {
        setIntegrationRedirects(response.data)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }, [])

  const connectAccount = (provider: IntegrationProviders, authorizationCode: string, state?: string) => {
    if (user && !isConnected(provider)) {
      setConnectingAccount(true)
      api
        .getAccessToken(provider, authorizationCode, state)
        .then((userResponse) => {
          updateUser(userResponse.data)
          setSnackbarMessage({ status: 'success', message: `Successfully connected your ${provider} account.` })
          setConnectingAccount(false)
          history.push(INTEGRATIONS)
        })
        .catch(() => {
          setSnackbarMessage({
            status: 'error',
            message: `Something went wrong connecting your ${provider} account. Please try again.`
          })
          setConnectingAccount(false)
          history.push(INTEGRATIONS)
        })
    }
  }

  const disconnectAccount = (provider: IntegrationProviders) => {
    if (user && isConnected(provider)) {
      setConnectingAccount(true)
      api
        .disconnectAccount(provider)
        .then((user) => {
          updateUser(user.data)
          setSnackbarMessage({ status: 'success', message: `Successfully disconnected your ${provider} account.` })
          setConnectingAccount(false)
        })
        .catch(() => {
          setSnackbarMessage({
            status: 'error',
            message: `Something went wrong disconnecting your ${provider} account. Please try again.`
          })
          setConnectingAccount(false)
        })
    }
  }

  const deleteAPIKey = async () => {
    if (user) {
      await api.deleteApiKey(user.apiKeys[selectedAPIKey])
      const updateUserResponse = await api.getMe()
      updateUser(updateUserResponse.data)
      setDeleteKeyDialogOpen(false)
    }
  }

  return (
    <div className="w-full" style={{ padding: '0 30px' }}>
      <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
        <div>API Keys</div>
        <div className="flex items-center" style={{ marginLeft: 'auto' }}>
          <a className="text-white flex" href={`https://docs.morta.io`} target="_blank" rel="noopener noreferrer">
            <Button internalType="secondary">API Docs</Button>{' '}
          </a>
          <Button data-cy="new-api-key" onClick={() => setNewKeyModalOpen(true)} style={{ marginLeft: '7px' }}>
            New API Key
          </Button>
        </div>
      </div>

      {user &&
        user.apiKeys &&
        user.apiKeys.map(function (apiKey: any, apiKeyNumber: number) {
          return (
            <div
              key={apiKeyNumber}
              className="flex flex-row items-center rounded heavy-shadow w-full p-10px flex-wrap my-20px border-1px border-solid border-black"
            >
              <div>
                <span className="font-bold">{apiKey.name}</span> (starts with {apiKey.prefix})
              </div>
              <Button
                onClick={() => {
                  setEditKeyModalOpen(true)
                  setSelectedAPIKey(apiKeyNumber)
                }}
                style={{ marginLeft: 'auto', marginRight: '10px', maxWidth: '150px' }}
                data-cy="edit-api-key"
              >
                Edit
              </Button>
              <Button
                internalType="danger"
                onClick={() => {
                  setDeleteKeyDialogOpen(true)
                  setSelectedAPIKey(apiKeyNumber)
                }}
                style={{ marginRight: '10px', maxWidth: '150px' }}
                data-cy="delete-api-key"
              >
                Delete
              </Button>
            </div>
          )
        })}

      {user && newKeyModalOpen && (
        <NewKeyModal
          id="new-api-key-modal"
          open={newKeyModalOpen}
          setOpen={setNewKeyModalOpen}
          setUserData={updateUser}
        />
      )}

      {user && user.apiKeys && selectedAPIKey !== -1 && (
        <EditKeyModal
          id="edit-api-key-modal"
          user={user}
          open={editKeyModalOpen}
          setOpen={setEditKeyModalOpen}
          onClose={() => {
            setSelectedAPIKey(-1)
          }}
          setUserData={updateUser}
          keyNumber={selectedAPIKey}
        />
      )}

      {user && user.apiKeys && selectedAPIKey !== -1 && (
        <Modal
          id="delete-api-key-modal"
          open={deleteKeyDialogOpen}
          setOpen={setDeleteKeyDialogOpen}
          title="Delete API Key"
        >
          Once you have deleted this API key it cannot be restored
          <div className="flex items-center justify-end mt-20px">
            <Button internalType="danger" onClick={() => setDeleteKeyDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '10px' }}
              internalType="accept"
              onClick={() => deleteAPIKey()}
              autoFocus
              data-cy="confirm-delete-api-key"
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}

      <div
        className="flex flex-row items-center w-full text-lg font-bold mb-20px py-10px border-b-1px border-solid border-grey"
        style={{ marginTop: '50px' }}
      >
        <div>Connected Accounts</div>
      </div>
      <div className="mb-20px text-lg font-semibold" style={{ backgroundColor: '#fff8c5', padding: '20px 5px' }}>
        <span className="text-lg" style={{ marginRight: '10px', color: '#9a6800' }}>
          ⚠
        </span>
        Prior to connecting your account, please log out of the respective platform in your browser to avoid any issues.
      </div>
      {integrationRedirects && integrationRedirects.viewpoint && (
        <IntegrationConnection
          provider={IntegrationProviders.VIEWPOINT}
          connected={isConnected(IntegrationProviders.VIEWPOINT)}
          redirectUrl={integrationRedirects.viewpoint}
          connectingAccount={connectingAccount}
          disconnectAccount={disconnectAccount}
          logo={'/assets/images/viewpoint.png'}
        />
      )}

      {integrationRedirects && integrationRedirects.autodesk && (
        <IntegrationConnection
          provider={IntegrationProviders.AUTODESK_BIM360}
          connected={isConnected(IntegrationProviders.AUTODESK_BIM360)}
          redirectUrl={integrationRedirects.autodesk}
          connectingAccount={connectingAccount}
          disconnectAccount={disconnectAccount}
          logo={'/assets/images/autodesk.png'}
        />
      )}

      {integrationRedirects && integrationRedirects.aconex && (
        <IntegrationConnection
          provider={IntegrationProviders.ACONEX}
          connected={isConnected(IntegrationProviders.ACONEX)}
          redirectUrl={integrationRedirects.aconex}
          connectingAccount={connectingAccount}
          disconnectAccount={disconnectAccount}
          logo={'/assets/images/aconex.png'}
        />
      )}

      {integrationRedirects && integrationRedirects.asite && (
        <IntegrationConnection
          provider={IntegrationProviders.ASITE}
          connected={isConnected(IntegrationProviders.ASITE)}
          redirectUrl={integrationRedirects.asite}
          connectingAccount={connectingAccount}
          disconnectAccount={disconnectAccount}
          logo={'/assets/images/asite.png'}
        />
      )}

      {integrationRedirects && integrationRedirects.procore && (
        <IntegrationConnection
          provider={IntegrationProviders.PROCORE}
          connected={isConnected(IntegrationProviders.PROCORE)}
          redirectUrl={integrationRedirects.procore}
          connectingAccount={connectingAccount}
          disconnectAccount={disconnectAccount}
          logo="/assets/images/procore.png"
        />
      )}

      {integrationRedirects && integrationRedirects.revizto && (
        <div>
          <div className="flex flex-column rounded heavy-shadow w-full p-10px flex-wrap my-20px border-1px border-solid border-black">
            <div className="flex flex-row items-center">
              <img src="/assets/images/revizto.png" alt="aconex logo" style={{ width: '150px', marginRight: 'auto' }} />
              {user && !user.reviztoConnected && reviztoAccessToken === undefined && (
                <div className="flex flex-row items-center" style={{ width: '300px' }}>
                  Region:
                  <select
                    style={{ marginLeft: '20px' }}
                    value={reviztoRegion}
                    onChange={(event) => selectReviztoRegion(event.target.value)}
                  >
                    <option value="">Select Revizto Region</option>
                    {Object.keys(REVIZTO_REGIONS).map((regionKey: string, regionIndex: number) => (
                      <option key={regionIndex} value={regionKey}>
                        {REVIZTO_REGIONS[regionKey]}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {user && !user.reviztoConnected && reviztoAccessToken === undefined && reviztoRegion !== '' && (
                <a href={integrationRedirects.revizto} target="_blank" rel="noopener noreferrer">
                  <Button
                    style={{ width: BUTTON_WIDTH }}
                    disabled={connectingAccount}
                    onClick={() => {
                      setReviztoAccessToken('')
                    }}
                  >
                    Get Access Token
                  </Button>
                </a>
              )}

              {user && !user.reviztoConnected && reviztoAccessToken === '' && reviztoRegion !== '' && (
                <Button
                  style={{ width: BUTTON_WIDTH }}
                  disabled={connectingAccount}
                  onClick={() => {
                    const accessToken = window.prompt('Enter your Revizto access token')
                    if (accessToken) {
                      connectAccount(IntegrationProviders.REVIZTO, accessToken, reviztoRegion)
                    } else {
                      setSnackbarMessage({
                        status: 'error',
                        message: 'Something went wrong connecting your Revizto account. Please try again.'
                      })
                    }
                  }}
                >
                  Enter Access Code
                </Button>
              )}

              {user && user.reviztoConnected && (
                <Button
                  internalType="danger"
                  style={{ width: BUTTON_WIDTH }}
                  onClick={() => disconnectAccount(IntegrationProviders.REVIZTO)}
                  disabled={connectingAccount}
                >
                  Disconnect Account
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Integrations
