import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { IUserObject } from 'types'

interface EditKeyModalProps extends ModalProps {
  user: IUserObject
  setUserData: (arg0: IUserObject) => void
  keyNumber: number
}

const EditKeyModal: React.FC<EditKeyModalProps> = ({ id, open, setOpen, onClose, user, setUserData, keyNumber }) => {
  const [apiKey, setAPIKey] = useState(user.apiKeys[keyNumber])

  const handleClick = async () => {
    // Check there are no errors
    if (!apiKey || apiKey.name === '') return null

    await api.updateApiKey(apiKey)
    const updateUserResponse = await api.getMe()

    setUserData(updateUserResponse.data)
    setOpen(false)
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} onClose={onClose} title="Update API Key">
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">API Key Name</div>
        <input
          className="w-full "
          data-cy="api-key-name-textfield"
          required
          defaultValue={apiKey.name}
          style={{ marginTop: '20px', marginBottom: '20px' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAPIKey({ ...apiKey, name: event.target.value })}
        />
      </div>
      <div className="flex justify-end mt-20px">
        <Button onClick={() => handleClick()} data-cy="api-key-save">
          Save Changes
        </Button>
      </div>
    </Modal>
  )
}

export default EditKeyModal
