import { IUserObject } from 'types'

export const PERMISSIONS = {
  viewer: 0,
  attentionOf: 1,
  contributor: 2,
  reviewer: 3,
  owner: 4
}

const AUTH_KEY = 'morta_user'
const MASQUERADE_KEY = 'morta_masquerade'

export const getAuthToken = () => {
  const currentUser = getCurrentUser()
  if (getMasqueradeToken()) {
    return `BearerMasquerade ${getMasqueradeToken()}`
  } else if (currentUser) {
    return `Bearer ${currentUser.authToken}`
  }
}

const isLocalStorageAvailable = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const getCurrentUser = (): IUserObject | undefined => {
  if (isLocalStorageAvailable()) {
    const data = localStorage.getItem(AUTH_KEY)
    if (data !== null) {
      return JSON.parse(data)
    }
  }
}

export const getMasqueradeToken = () => {
  return localStorage.getItem(MASQUERADE_KEY)
}

export const setMasqueradeToken = (token: string) => {
  localStorage.setItem(MASQUERADE_KEY, token)
}

export const clearMasqueradeToken = () => {
  localStorage.removeItem(MASQUERADE_KEY)
}
